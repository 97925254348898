import { ReactSVG } from "react-svg";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";

import leftArrow from "../assets/icons/svg/left-arrow.svg";
import rightArrow from "../assets/icons/svg/right-arrow.svg";
import graffiti from "../assets/icons/svg/graffiti-blue.svg";
import solarPanel from "../assets/icons/svg/solar-panel-blue.svg";
import pool from "../assets/icons/svg/pool-blue.svg";
import window from "../assets/icons/svg/window-open-bleu.svg";
import postalActivity from "../assets/icons/png/activity-postal-blanc.png";
import calendar from "../assets/icons/png/calendar.png";
import climateData from "../assets/icons/png/climate-data.png";
import mapInfo from "../assets/icons/png/map-info-blanc.png";
import paperPlane from "../assets/icons/png/paper-plane-blanc.png";
import streetview from "../assets/icons/png/street-view-blanc.png";
import thief from "../assets/icons/png/thief.png";
import crack from "../assets/icons/svg/crack-wall-blue.svg";
import storm from "../assets/icons/png/storm.png";
import wind from "../assets/icons/png/wind.png";
import snow from "../assets/icons/png/snow.png";
import heatWave from "../assets/icons/png/heat-wave.png";
import coldWave from "../assets/icons/png/cold-wave.png";
import heavyRain from "../assets/icons/png/rain.png";
import ice from "../assets/icons/png/ice.png";
import flood from "../assets/icons/png/flood.png";
import pollution from "../assets/icons/png/pollution.png";
import submersion from "../assets/icons/png/submersion.png";
import simpleHeatWave from "../assets/icons/png/simple-heat-wave.png";
import catnatData from "../assets/icons/png/alert-svgrepo-com.png";
import perilData from "../assets/icons/svg/icon-arrete-peril.svg";
import storm_catnat from "../assets/icons/png/storm_catnat.png";
import flood_catnat from "../assets/icons/png/icon-heavy-rain 5.png";
import submersion_catnat from "../assets/icons/png/icon-submersion 9.png";
import drought_catnat from "../assets/icons/png/icon-secheresse-fondB.png";
import landslides_catnat from "../assets/icons/png/icone-eboulement.png";
import earthquake_catnat from "../assets/icons/png/icon-tremblement-terre.png";
import avalanche_catnat from "../assets/icons/png/icone-avalanche.png";
import warning from "../assets/icons/png/alert-svgrepo-com-red.png";
import pasRisque from "../assets/icons/png/icon-pas-de-risque.png";
import risqueFaible from "../assets/icons/png/icon-risque-faible.png";
import risqueModere from "../assets/icons/png/icon-risque-modere.png";
import risqueEleve from "../assets/icons/png/icon-risque-eleve.png";
import risqueTresEleve from "../assets/icons/png/icon-risque-tres-eleve.png";
import polution_alert from "../assets/icons/png/pollution_alert.png";
import cold_alert from "../assets/icons/png/gel.png";
import hot_alert from "../assets/icons/png/canicule.png";
import snow_alert from "../assets/icons/png/neige.png";
import flood_alert from "../assets/icons/png/innondation.png";
import drought_alert from "../assets/icons/png/secheresse.png";
import fire_alert from "../assets/icons/png/incendie.png";
import wind_alert from "../assets/icons/png/vent.png";
import storm_alert from "../assets/icons/png/orage.png";
import simplehot_alert from "../assets/icons/png/tres-forte-chaleur-rondplein.png";
import calendarDateTime from "../assets/icons/png/daterangecalendar.png";
import data4home from "../assets/icons/png/Data4home.png";
import data4weather from "../assets/icons/png/Data4weather.png";
import menu from "../assets/icons/png/menu.png";
import pencilBlack from "../assets/icons/svg/pencil-black.svg";
import pencilGray from "../assets/icons/svg/pencil-gray.svg";
import trashBlack from "../assets/icons/svg/trash-black.svg";
import trashGray from "../assets/icons/svg/trash-gray.svg";
import site_seveso from "../assets/icons/svg/icon-seveso.svg";
import logo_d4r from "../assets/icons/png/data4risk-logo.png";
import eye_close from "../assets/icons/svg/eye_close.svg";
import eye_open from "../assets/icons/svg/eye_open.svg";
import lock from "../assets/icons/svg/lock.svg";
import mail from "../assets/icons/svg/mail.svg";

import {
  FloodSvg,
  PluvialFloodSvg,
  FireSvg,
  DroughtSvg,
  HailSvg,
  HeavyRainSvg,
  WindSvg,
  CoastalErosionSvg,
  SubmersionSvg,
} from "./AssetSvg";

interface IconPropsType {
  type: string;
  containerStyle?: object;
  iconStyle?: object;
  size?: string;
}

const containerIconStyle: Record<string, string> = {
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  marginLeft: "5px",
  marginRight: "5px",
};

export default function Icon({
  type,
  containerStyle,
  iconStyle,
  size,
}: IconPropsType): JSX.Element {
  const iconFactory: Record<string, JSX.Element> = {
    leftArrow: <ReactSVG src={leftArrow} />,
    rightArrow: <ReactSVG src={rightArrow} />,
    visibility: <VisibilityIcon />,
    visibilityOff: <VisibilityOffIcon />,
    refresh: <RefreshIcon />,
    graffiti: <ReactSVG src={graffiti} />,
    solarPanel: <ReactSVG src={solarPanel} />,
    pool: <ReactSVG src={pool} />,
    window: <ReactSVG src={window} />,
    crack: <ReactSVG src={crack} />,
    postalActivity: <img src={postalActivity} alt="icon" />,
    calendar: <img src={calendar} alt="icon" />,
    catnatData: <img src={catnatData} alt="icon" />,
    perilData: <img src={perilData} alt="icon" />,
    site_seveso: <img src={site_seveso} alt="icon" />,
    climateData: <img src={climateData} alt="icon" />,
    storm_catnat: (
      <img src={storm_catnat} alt="icon" style={containerIconStyle} />
    ),
    flood_catnat: (
      <img src={flood_catnat} alt="icon" style={containerIconStyle} />
    ),
    landslide_catnat: (
      <img src={landslides_catnat} alt="icon" style={containerIconStyle} />
    ),
    submersion_catnat: (
      <img src={submersion_catnat} alt="icon" style={containerIconStyle} />
    ),
    drought_catnat: (
      <img src={drought_catnat} alt="icon" style={containerIconStyle} />
    ),
    avalanche_catnat: (
      <img src={avalanche_catnat} alt="icon" style={containerIconStyle} />
    ),
    earthquake_catnat: (
      <img src={earthquake_catnat} alt="icon" style={containerIconStyle} />
    ),
    warning: <img src={warning} alt="icon" style={iconStyle} />,
    mapInfo: <img src={mapInfo} alt="icon" />,
    paperPlane: <img src={paperPlane} alt="icon" />,
    streetview: (
      <img src={streetview} alt="icon" style={{ paddingLeft: "10px" }} />
    ),
    thief: <img src={thief} alt="icon" />,
    THUNDER: <img src={storm} alt="icon" />,
    WINDS: <img src={wind} alt="icon" style={{ width: "500px" }} />,
    SNOW: <img src={snow} alt="icon" />,
    HEAT_WAVE: (
      <img
        src={heatWave}
        alt="icon"
        style={{ width: "50px", paddingLeft: "10px" }}
      />
    ),
    COLD_WAVE: <img src={coldWave} alt="icon" style={{ width: "30px" }} />,
    HEAVY_RAIN: <img src={heavyRain} alt="icon" style={{ width: "45px" }} />,
    ICE: <img src={ice} alt="icon" style={{ width: "40px" }} />,
    FLOOD: <img src={flood} alt="icon" style={iconStyle} />,
    POLLUTION: <img src={pollution} alt="icon" style={iconStyle} />,
    SUBMERSION: <img src={submersion} alt="icon" style={{ width: "35px" }} />,
    SIMPLE_HEAT_WAVE: (
      <img src={simpleHeatWave} alt="icon" style={{ width: "45px" }} />
    ),
    risque_lv0: <img src={pasRisque} alt="icon" style={iconStyle} />,
    risque_lv1: <img src={risqueFaible} alt="icon" style={iconStyle} />,
    risque_lv2: <img src={risqueModere} alt="icon" style={iconStyle} />,
    risque_lv3: <img src={risqueEleve} alt="icon" style={iconStyle} />,
    risque_lv4: <img src={risqueTresEleve} alt="icon" style={iconStyle} />,
    flood_svg: <FloodSvg style={iconStyle} size={size} />,
    pluvialFlood_svg: <PluvialFloodSvg style={iconStyle} size={size} />,
    fire_svg: <FireSvg style={iconStyle} size={size} />,
    drought_svg: <DroughtSvg style={iconStyle} size={size} />,
    hailStorm_svg: <HailSvg style={iconStyle} size={size} />,
    heavyRain_svg: <HeavyRainSvg style={iconStyle} size={size} />,
    wind_svg: <WindSvg style={iconStyle} size={size} />,
    coastalErosion_svg: <CoastalErosionSvg style={iconStyle} size={size} />,
    submersion_svg: <SubmersionSvg style={iconStyle} size={size} />,
    info: <InfoOutlinedIcon />,
    drag: <DragIndicatorOutlinedIcon sx={iconStyle} />,
    polution_alert: <img src={polution_alert} alt="icon" style={iconStyle} />,
    cold_alert: <img src={cold_alert} alt="icon" style={iconStyle} />,
    hot_alert: <img src={hot_alert} alt="icon" style={iconStyle} />,
    snow_alert: <img src={snow_alert} alt="icon" style={iconStyle} />,
    flood_alert: <img src={flood_alert} alt="icon" style={iconStyle} />,
    drought_alert: <img src={drought_alert} alt="icon" style={iconStyle} />,
    fire_alert: <img src={fire_alert} alt="icon" style={iconStyle} />,
    wind_alert: <img src={wind_alert} alt="icon" style={iconStyle} />,
    storm_alert: <img src={storm_alert} alt="icon" style={iconStyle} />,
    simplehot_alert: <img src={simplehot_alert} alt="icon" style={iconStyle} />,
    calendarDateTime: (
      <img src={calendarDateTime} alt="icon" style={iconStyle} />
    ),
    data4home: <img src={data4home} alt="icon" style={iconStyle} />,
    data4weather: <img src={data4weather} alt="icon" style={iconStyle} />,
    menu: <img src={menu} alt="icon" style={iconStyle} />,
    pencilBlack: <img src={pencilBlack} alt="icon" style={iconStyle} />,
    pencilGray: <img src={pencilGray} alt="icon" style={iconStyle} />,
    trashBlack: <img src={trashBlack} alt="icon" style={iconStyle} />,
    trashGray: <img src={trashGray} alt="icon" style={iconStyle} />,
    logo_d4r: <img src={logo_d4r} alt="icon" style={iconStyle} />,
    eye_close: <img src={eye_close} alt="icon" style={iconStyle} />,
    eye_open: <img src={eye_open} alt="icon" style={iconStyle} />,
    lock: <img src={lock} alt="icon" style={iconStyle} />,
    mail: <img src={mail} alt="icon" style={iconStyle} />,
  };

  return (
    <div className="iconWrapper" style={{ display: "flex", ...containerStyle }}>
      {iconFactory[type]}
    </div>
  );
}
