import { BlockItemType, RiskType, ClimateRiskType } from "../types";

export const blockItems: BlockItemType[] = [
  {
    title: "blockTitle.general",
    id: "map_search",
    iconType: "mapInfo",
  },
  {
    title: "blockTitle.activities",
    id: "business_search",
    iconType: "postalActivity",
  },
  {
    title: "blockTitle.aerial",
    id: "satellite_image_search",
    iconType: "paperPlane",
  },
  {
    title: "blockTitle.street",
    id: "streetview_image_search",
    iconType: "streetview",
  },
  {
    title: "blockTitle.weather",
    id: "weather_search",
    iconType: "calendar",
  },
  {
    title: "blockTitle.climateChange",
    id: "climate_risk",
    iconType: "climateData",
  },
  {
    title: "blockTitle.catNat",
    id: "catnat_risk",
    iconType: "catnatData",
  },
  {
    title: "blockTitle.mandates",
    id: "peril_risk",
    iconType: "perilData",
  },
  {
    title: "blockTitle.seveso",
    id: "site_seveso",
    iconType: "site_seveso",
  },
];

export const weatherRisk: RiskType[] = [
  { id: "THUNDER", label: "weatherLabel.thunder" },
  { id: "WINDS", label: "weatherLabel.winds" },
  { id: "SNOW", label: "weatherLabel.snow" },
  { id: "HEAT_WAVE", label: "weatherLabel.heatWave" },
  { id: "SIMPLE_HEAT_WAVE", label: "weatherLabel.simpleHeatWave" },
  { id: "COLD_WAVE", label: "weatherLabel.coldWave" },
  { id: "HEAVY_RAIN", label: "weatherLabel.heavyRain" },
  { id: "ICE", label: "weatherLabel.ice" },
  { id: "FLOOD", label: "weatherLabel.flood" },
  { id: "POLLUTION", label: "weatherLabel.pollution" },
  { id: "SUBMERSION", label: "weatherLabel.submersion" },
];

export const climateRiskItems: ClimateRiskType[] = [
  { id: "flood", catnatTypes: ["flood"], label: "riskLabel.flood" },
  { id: "drought", catnatTypes: ["drought"], label: "riskLabel.drought" },
  { id: "fire", catnatTypes: ["fire"], label: "riskLabel.fire" },
  { id: "hailStorm", catnatTypes: ["hailStorm"], label: "riskLabel.hailStorm" },
  {
    id: "heavyRain",
    catnatTypes: ["heavyRain"],
    label: "riskLabel.heavyRain",
  },
  { id: "wind", catnatTypes: ["wind"], label: "riskLabel.wind" },
  { id: "submersion", catnatTypes: ["submersion"], label: "riskLabel.submersion" },
  {
    id: "coastalErosion",
    catnatTypes: ["coastalErosion"],
    label: "riskLabel.coastalErosion",
  },
  {
    id: "pluvialFlood",
    catnatTypes: ["flood"],
    label: "riskLabel.pluvialFlood",
  },
];

export type scaleLevelType = {
  color?: string,
  desc?: string,
  num: number,
  label?: string,
  flood_level?: number | null,
  info?: Record<string, string>
}

export const scaleLevels: Record<number, scaleLevelType> = {
  [-1]: {
    color: "#cdcdcd",
    desc: "disabled",
    num: -1,
    label: "scaleLabel.disabled",
    flood_level: null,
    info: {
      coastalErosion: "infoRisk.coastalErosion.disabled",
      submersion: "infoRisk.submersion.disabled",
    },
  },
  0: {
    color: "#007200",
    desc: "no-risk",
    num: 0,
    label: "scaleLabel.noRisk",
    flood_level: 0,
    info: {
      drought: "infoRisk.drought.noRisk",
      flood: "infoRisk.flood.noRisk",
      pluvialFlood: "infoRisk.pluvialFlood.noRisk",
      fire: "infoRisk.fire.noRisk",
      hailStorm: "infoRisk.hailStorm.noRisk",
      wind: "infoRisk.wind.noRisk",
      heavyRain: "infoRisk.heavyRain.noRisk",
      coastalErosion: "infoRisk.coastalErosion.noRisk",
      submersion: "infoRisk.submersion.noRisk"
    },
  },
  1: {
    color: "#FFD60A",
    desc: "low",
    num: 1,
    label: "scaleLabel.low",
    flood_level: 0.2,
    info: {
      drought: "infoRisk.drought.low",
      flood: "infoRisk.flood.low",
      pluvialFlood: "infoRisk.pluvialFlood.low",
      fire: "infoRisk.fire.low",
      hailStorm: "infoRisk.hailStorm.low",
      wind: "infoRisk.wind.low",
      heavyRain: "infoRisk.heavyRain.low",
      coastalErosion: "infoRisk.coastalErosion.low",
      submersion: "infoRisk.submersion.low"
    },
  },
  2: {
    color: "#FF9E00",
    desc: "moderate",
    num: 2,
    label: "scaleLabel.moderate",
    flood_level: 0.5,
    info: {
      drought: "infoRisk.drought.moderate",
      flood: "infoRisk.flood.moderate",
      pluvialFlood: "infoRisk.pluvialFlood.moderate",
      fire: "infoRisk.fire.moderate",
      hailStorm: "infoRisk.hailStorm.moderate",
      wind: "infoRisk.wind.moderate",
      heavyRain: "infoRisk.heavyRain.moderate",
      coastalErosion: "infoRisk.coastalErosion.moderate",
      submersion: "infoRisk.submersion.moderate"
    },
  },
  3: {
    color: "#F4524D",
    desc: "high",
    num: 3,
    label: "scaleLabel.high",
    flood_level: 0.75,
    info: {
      drought: "infoRisk.drought.high",
      flood: "infoRisk.flood.high",
      pluvialFlood: "infoRisk.pluvialFlood.high",
      fire: "infoRisk.fire.high",
      hailStorm: "infoRisk.hailStorm.high",
      wind: "infoRisk.wind.high",
      heavyRain: "infoRisk.heavyRain.high",
      coastalErosion: "infoRisk.coastalErosion.high",
      submersion: "infoRisk.submersion.high"
    },
  },
  4: {
    color: "#EC0808",
    desc: "very high",
    num: 4,
    label: "scaleLabel.veryHigh",
    flood_level: 1.4,
    info: {
      drought: "infoRisk.drought.veryHigh",
      flood: "infoRisk.flood.veryHigh",
      pluvialFlood: "infoRisk.pluvialFlood.veryHigh",
      fire: "infoRisk.fire.veryHigh",
      hailStorm: "infoRisk.hailStorm.veryHigh",
      wind: "infoRisk.wind.veryHigh",
      heavyRain: "infoRisk.heavyRain.veryHigh",
      coastalErosion: "infoRisk.coastalErosion.veryHigh",
      submersion: "infoRisk.submersion.veryHigh"
    },
  },
  5: {
    num: 5,
    // label: "Il y a difference niveau de risque incendie",
    info: {
      fire: "infoRisk.fire.diffLevel",
    },
  },
};

export const buildingDataInfo = [
  {
    label: "Surface au sol calculée depuis la geoshape",
    info: "La surface au sol prend en compte l'ensemble des bâtiments à l'adresse postale.",
  },
  {
    label: "Estimation surface habitable",
    info: "La surface habitable est estimée sur la base du nombre total d'étage, mais ne prend pas en compte les caves.",
  },
  {
    label: "Hauteur du bâtiment",
    info: "Hauteur totale du bâtiment incluant les combles, sans les caves",
  },
];
